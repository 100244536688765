<template>
    <div ref="hoverElement" style="background-color: cornsilk!important;max-width: 600px;text-wrap: pretty;"
        class="popover-personinfo bs-popover-auto fade shadow" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" style="background-color: cornsilk!important;">
            <div class="fw-bold d-flex ">
                <div>
                    <small>{{ ActionInfo.DescriptionIntro }} (ID: {{ ActionInfo.ID}})</small>
                </div>
                <div class="ms-auto me-1" style="cursor: default">
                    <a :href="`/${ActionInfo.BaseUrl}`"><i class="bi bi-box-arrow-up-right ms-1"></i></a>
                </div>
            </div>
            <div class="col-12 mb-0 mt-0 fst-italic" style="font-size:smaller">
                <span>{{ $t("From") }}</span>&nbsp;<span>{{ ActionInfo.ItemTitle }} {{
                    ActionInfo.ObjectName }} ({{ ActionInfo.Item_ID }})</span>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{ $t('Loading...') }}
        </h3>
        <div class="popover-body p-2">
            <template v-if="isLoaded">
                <div class="row gx-5" style="font-size:small">

                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("OrgUnit")
                        }}:</span>&nbsp;<span>{{ ActionInfo.OrgUnit }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="ActionInfo.DueDate">
                        <span style="font-weight: 500;">{{ $t("Due Date")
                        }}:</span>&nbsp;<span>{{ $formatDate(ActionInfo.DueDate, 'Short Date') }}</span>
                    </div>
                    <div class="col-12 mb-0" v-if="ActionInfo.Closed">
                        <span style="font-weight: 500;">{{ $t("Closed")
                        }}:</span>&nbsp;<span>{{ ActionInfo.Closed }}</span>
                    </div>
                    <div class="col-12 mb-0 mt-1 fst-italic" style="font-size:smaller">
                        {{ $t("Action created by") }}: {{ ActionInfo.CreatedBy }} {{ $formatDate(ActionInfo.Created, 'Short Date') }}
                    </div>
                </div>
            </template>
            <template v-else>
                {{$t('Loading...')}}
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { API } from 'o365-modules';
import { ref, watch, onMounted } from 'vue';
import { alert } from 'o365-vue-services';

export interface IActionInfo {
    PrimKey: string;
    ID: string;
    Comment: string;
    BaseUrl: string;
    ItemTitle: string;
    ObjectName: string;
    Item_ID: string;
    OrgUnit: string;
    DueDate: Date;
    Closed: Date;
    DescriptionIntro: string;
    CreatedBy: string;
    Created: Date;
};
export interface IActivityHoverProps {
    PrimKey?: string;
}

const showContent = ref(false);
const isLoaded = ref(false);
const ActionInfo = ref<IActionInfo | null>(null);
const ActionCache = new Map<number, IActionInfo>();
const hoverElement = ref<HTMLElement | null>(null);

const props = defineProps<IActivityHoverProps>();

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && ActionInfo.value?.PrimKey != `${props.PrimKey}`) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({ hoverElement, hoverHide, hoverShow });

async function handleShow() {
    console.log("test");
    console.log(isLoaded.value);
    console.log(props);
    isLoaded.value = false;
    ActionInfo.value = null;
    let actionData = ActionCache.get(props.PrimKey);
    if (actionData == null) {
        try {
            actionData = await loadActionData(props.PrimKey);
            if (actionData == null) { return; }
            ActionCache.set(props.PrimKey, actionData);
        } catch (ex) {
            console.error(ex);
            return;
        }
    }
    console.log(actionData);
    ActionInfo.value = actionData;
    isLoaded.value = true;
}

async function loadActionData(PrimKey: string) {
    const result = await API.request({
        requestInfo: '/nt/api/data/aviw_Scope_MyActions',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "aviw_Scope_MyActions",
            "definitionProc": "astp_Scope_MyActionsDefinition",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "PrimKey", type: "uniqueidentifier" },
                { name: "ID", type: "string" },
                { name: "Comment", type: "Comment" },
                { name: "BaseUrl", type: "string" },
                { name: "ItemTitle", type: "string" },
                { name: "ObjectName", type: "string" },
                { name: "Item_ID", type: "string" },
                { name: "OrgUnit", type: "string" },
                { name: "DueDate", type: "date" },
                { name: "Closed", type: "date" },
                { name: "DescriptionIntro", type: "string" },
                { name: "CreatedBy", type: "string" },
                { name: "Created", type: "date" },
            ],
            "maxRecords": 1,
            "whereClause": `PrimKey = '${PrimKey}'`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });

    return result !== null ? result[0] : [];
}

watch(() => props.PrimKey, (_newPrimKey) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});
</script>